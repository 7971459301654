// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (min-width:992px){.vPszkVBpSpcIjJHv4uHVO:before{content:\"\";background:rgba(0,0,0,.66666);position:fixed;left:0;top:0;right:0;bottom:0;z-index:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "vPszkVBpSpcIjJHv4uHVO"
};
module.exports = ___CSS_LOADER_EXPORT___;
